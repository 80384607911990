<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Fill in the missing information for each of the following 3
        <strong>neutral</strong> isotopes:
      </p>

      <table style="width: 450px">
        <thead>
          <tr>
            <th />
            <th style="font-size: 15px; text-align: center">
              <stemble-latex content="$\text{Q1}$" />
            </th>
            <th style="font-size: 15px; text-align: center">
              <stemble-latex content="$\text{Q2}$" />
            </th>
            <th style="font-size: 15px; text-align: center">
              <stemble-latex content="$\text{Q3}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="text-align: left">
              <stemble-latex content="$\text{Element:}$" />
            </td>
            <td class="centered-input" style="text-align: center">
              {{ isotope1Element }}
            </td>
            <td class="centered-input" style="text-align: center">
              <chemical-symbol-input v-model="inputs.input4" class="ma-2" dense />
            </td>
            <td class="centered-input" style="text-align: center">
              <chemical-symbol-input v-model="inputs.input7" class="ma-2" dense />
            </td>
          </tr>
          <tr>
            <td style="text-align: left">
              <stemble-latex content="$\text{Mass Number:}$" />
            </td>
            <td style="text-align: center">
              {{ isotope1MassNumber }}
            </td>
            <td style="text-align: center">
              {{ isotope2MassNumber }}
            </td>
            <td class="centered-input" style="text-align: center">
              <calculation-input v-model="inputs.input8" class="ma-2" dense />
            </td>
          </tr>
          <tr>
            <td style="text-align: left">
              <stemble-latex content="$\text{Neutrons:}$" />
            </td>
            <td class="centered-input" style="text-align: center">
              <calculation-input v-model="inputs.input1" class="ma-2" dense />
            </td>
            <td style="text-align: center">
              {{ isotope2Neutrons }}
            </td>
            <td style="text-align: center">
              {{ isotope3Neutrons }}
            </td>
          </tr>
          <tr>
            <td style="text-align: left">
              <stemble-latex content="$\text{Protons:}$" />
            </td>
            <td class="centered-input" style="text-align: center">
              <calculation-input v-model="inputs.input2" class="ma-2" dense />
            </td>
            <td class="centered-input" style="text-align: center">
              <calculation-input v-model="inputs.input5" class="ma-2" dense />
            </td>
            <td class="centered-input" style="text-align: center">
              <calculation-input v-model="inputs.input9" class="ma-2" dense />
            </td>
          </tr>
          <tr>
            <td style="text-align: left">
              <stemble-latex content="$\text{Electrons:}$" />
            </td>
            <td class="centered-input" style="text-align: center">
              <calculation-input v-model="inputs.input3" class="ma-2" dense />
            </td>
            <td class="centered-input" style="text-align: center">
              <calculation-input v-model="inputs.input6" class="ma-2" dense />
            </td>
            <td style="text-align: center">
              {{ isotope3Electrons }}
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question2',
  components: {
    ChemicalSymbolInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
      },
    };
  },
  computed: {
    chemicalIsotope1() {
      return this.taskState.getValueBySymbol('isotope1').content;
    },
    isotope1Element() {
      return this.chemicalIsotope1.symbol;
    },
    isotope1MassNumber() {
      return this.chemicalIsotope1.massNumber;
    },
    chemicalIsotope2() {
      return this.taskState.getValueBySymbol('isotope2').content;
    },
    isotope2MassNumber() {
      return this.chemicalIsotope2.massNumber;
    },
    isotope2Neutrons() {
      return this.chemicalIsotope2.neutrons;
    },
    chemicalIsotope3() {
      return this.taskState.getValueBySymbol('isotope3').content;
    },
    isotope3Neutrons() {
      return this.chemicalIsotope3.neutrons;
    },
    isotope3Electrons() {
      return this.chemicalIsotope3.electrons;
    },
  },
};
</script>

<style scoped>
td {
  vertical-align: center;
  text-align: center;
}
</style>
